import angular from 'angular'
import swal from 'sweetalert'

angular.module('guaruclean.controllers.funcionarios', [])
.controller('funcionariosCtrl',['$scope', '$rootScope', '$state', '$q', '$filter', 'funcionariosAPI', 'NgTableParams', function ($scope, $rootScope, $state, $q, $filter, funcionariosAPI, NgTableParams) {

   $scope.Funcionarios = []

   $scope.Lista = new NgTableParams({
    total: 10,
    page: 1
   }, {
    getData: function(params){
        return funcionariosAPI.Pesquisar($scope.filtro).then(function(resp){
            let qtdTotal   = resp.data.length
            $scope.Funcionarios = resp.data
            $scope.Funcionarios = params.sorting() ? $filter('orderBy')($scope.Funcionarios, params.orderBy()) : $scope.Funcionarios
            $scope.Funcionarios = $scope.Funcionarios.slice((params.page() - 1) * params.count(), params.page() * params.count())
            
            params.total(qtdTotal)
            return $scope.Funcionarios
        })
    }})

   $scope.Pesquisar = (filtro) => {
    $scope.Lista = new NgTableParams({
        total: 10,
        page: 1
        }, {
            getData: function(params){
            return funcionariosAPI.Pesquisar(filtro).then(function(resp){
                let qtdTotal   = resp.data.length
                $scope.Funcionarios = resp.data
                $scope.Funcionarios = params.sorting() ? $filter('orderBy')($scope.Funcionarios, params.orderBy()) : $scope.Funcionarios
                $scope.Funcionarios = $scope.Funcionarios.slice((params.page() - 1) * params.count(), params.page() * params.count())

                params.total(qtdTotal)
                return $scope.Funcionarios
            })
        }})
   }

   $scope.Selecionar = () => {
       if ($state.params.funcionarioId > 0) {
        funcionariosAPI.Selecionar($state.params.funcionarioId)
            .then(resp => {
                $scope.$apply(function() {
                    $scope.dados = resp.data
                })
            })
            .catch(err => {
                console.log(err)
            })
       }
   }

   $scope.Upsert = (funcionario) => {
       funcionariosAPI.Upsert(funcionario)
        .then(resp => {
            if (resp.status === 200) {
                swal("Sucesso!", `Seu funcionário foi ${ funcionario.funcionarioId > 0 ? 'atualizado' : 'inserido' } com sucesso!`, "success")
                    .then(resp => {
                        if (resp) {
                            $state.go('app.funcionarios.lista')
                        }
                    })
                    .catch(err => {
                        console.log(err)
                    })
            }
        })
        .catch(err => {
            console.log(err)
        })
   }

   $scope.Deletar = (funcionario) => {
    swal({
        title: "Tem certeza que deseja deletar?",
        text: "Uma vez deletado, o registro não poderá mais ser recuperado.",
        icon: "warning",
        buttons: true,
        dangerMode: true,
        })
        .then((willDelete) => {
            if (willDelete) {

                funcionariosAPI.Deletar(funcionario.funcionarioId)
                    .then(resp => {
                        if (resp.status === 200) {
                            swal("Sucesso!", `Seu funcionário foi deletado com sucesso!`, "success")
                            .then(resp => {
                                if (resp) {
                                    $state.go('app.funcionarios.lista')
                                }
                            })
                            .catch(err => {
                                console.log(err)
                            })
                        }
                    })
                    .catch(err => {
                        swal("Erro!", `Occoreu um erro inesperado! Tente novamente em instantes.`, "error")
                            .then(resp => {
                                if (resp) {
                                    $state.go('app.funcionarios.lista')
                                }
                            })
                            .catch(err => {
                                console.log(err)
                            })
                    })

            } else {
                return false
            }
        })
    }

    $scope.addContato = (contato) => {
        if (!contato) return false;

        if ($scope.dados.contato_funcionarios) {

            let exist = _.find($scope.dados.contato_funcionarios, contato )
            if (!exist) {
                $scope.dados.contato_funcionarios.push(contato)
                delete $scope.novoContato
            }

        } else {
            $scope.dados.contato_funcionarios = [
                contato
            ]
            delete $scope.novoContato
        }
    }

    $scope.removeContato = (contato) => {
        if (!contato) return false;

        swal({
            title: "Tem certeza que deseja deletar?",
            text: "Uma vez deletado, o registro não poderá mais ser recuperado.",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {

                    let ctt = _.findIndex($scope.dados.contato_funcionarios, contato)
                    if(ctt != -1) {
                        $scope.$apply(function() {
                            if ($scope.dados.contato_funcionarios.length > 1) {
                                _.remove($scope.dados.contato_funcionarios, contato)
                            } else {
                                $scope.dados.contato_funcionarios = []
                            }
                        })
                    }

                } else {
                    return false
                }
            });

    }

   $scope.init = () => {
       
   }

   $scope.init()

}])
