import angular from 'angular'
import axios from 'axios'

angular.module('guaruclean.services.chamados', [])

.service('chamadosAPI', ['$rootScope', '$http', '$cookies', function($rootScope, $http, $cookies){

    this.endpoint = axios.create({
        baseURL: `//${window.location.host}/api/`,
        timeout: 60000,
        headers: {
            'x-access-token': ( $cookies.get('__grclean-tkn') ? $cookies.get('__grclean-tkn') : null )
        }
    })

    this.endpoint.interceptors.request.use(function (config) {
      // Do something before request is sent
      return config;
    }, function (error) {
      // Do something with request error
      return Promise.reject(error);
    });

    this.Pesquisar = (dados) => {
        return this.endpoint.post('chamado/', dados)
    }

    this.Selecionar = (id) => {
        return this.endpoint.get('chamado/', {
            params: {
                chamadoId: id
            }
        })
    }

    this.Upsert = (dados) => {
        return this.endpoint.put('chamado/', {
            params: {
                chamadoId: dados.chamadoId
            },
            data: dados
        })
    }

    this.NovosChamados = () => {
        return this.endpoint.get('chamado/novos')
    }

    this.Deletar = (id) => {
        return this.endpoint.delete('chamado/', {
            params: {
                chamadoId: id
            }
        })
    }

    this.Imprimir = async (id) => {
      return this.endpoint.get(`chamado/imprimir/${id}`, {
        responseType: 'arraybuffer'
      });
    }

    return this

}])
