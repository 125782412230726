import angular from 'angular'

angular.module('guaruclean.directives.ddCidade', [])

.directive('ddCidade', [ 'commonAPI', function(commonAPI) {
    return {
        restrict: 'E',
        scope: {
            current: '=ngModel'
        },
        template: `<select id="ddCidade" ng-model="current" ng-disabled="Cidades == undefined" ng-options="item.Id as item.Nome for item in Cidades" class="form-control">
                   </select>`,
        controller: [ '$scope', '$rootScope', function($scope, $rootScope) {

            $rootScope.$on('unlockCidades', (event, param) => {
                $scope.buscarCidades(param.estadoId)
            })

            $scope.buscarCidades = (uf) => {
                commonAPI.BuscarCidades(uf)
                    .then(resp => {
                        $scope.$apply(() => {
                            $scope.Cidades = resp.data
                        })
                    })
                    .catch(err => {
                        console.log(err)
                    })
            }
            
        }],
        link: function (scope, element, attrs) {

        }

    }
}])