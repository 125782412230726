import angular from 'angular'

angular.module('guaruclean.directives.uploadPic', [])

.directive('uploadPic', [ 'Upload', '$cookies', '$timeout', function(Upload, $cookies, $timeout) {
    return {
        restrict: 'E',
        scope: {
            url: '@',
            model: '=ngModel'
        },
        require: ['ngModel'],
        templateUrl: './directives/view/upload-pic.html',
        link: function (scope, element, attrs, ngModel) {

            scope.upload = (file) => {
                scope.loading = true

                Upload.upload({
                    url: scope.url,
                    data: { file: file },
                    headers: { 'x-access-token': $cookies.get('__grclean-tkn') }
                }).then(function (resp) {

                    $timeout(function() {
                        scope.$apply(function() {
                            ngModel[0].$setViewValue(resp.data.imgUrl)
                            scope.loading = false
                        })
                    }, 500)

                }, function (resp) {
                    console.log(resp)
                }, function (evt) {
                    console.log(evt)
                    var progressPercentage = parseInt(100.0 * evt.loaded / evt.total)
                    scope.progress = progressPercentage
                })

            }

        }

    }
}])