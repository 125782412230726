import angular from 'angular'

import usuario from './usuario'
import app from './app'
import recados from './recados'
import clientes from './clientes'
import chamados from './chamados'
import configuracoes from './configuracoes'
import funcionarios from './funcionarios'
import agenda from './agenda'

let $inject = [
    'guaruclean.routes.usuario',
    'guaruclean.routes.app',
    'guaruclean.routes.recados',
    'guaruclean.routes.clientes',
    'guaruclean.routes.chamados',
    'guaruclean.routes.configuracoes',
    'guaruclean.routes.funcionarios',
    'guaruclean.routes.agenda'
]

angular.module('guaruclean.routes', $inject)
