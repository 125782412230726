import angular from 'angular'
import dtpk from './datePicker'
import ddest from './ddEstado'
import ddcdd from './ddCidade'
import contato from './contatoCliente'
import usuarioLogado from './usuarioLogado'
import quantidadeRecados from './quantidadeRecados'
import quantidadeChamados from './quantidadeChamados'
import sidebar from './sidebar'
import ck from './ckEditor'
import uploadPic from './upload-pic'

let $inject = [
    'guaruclean.directives.datePicker',
    'guaruclean.directives.ddEstado',
    'guaruclean.directives.ddCidade',
    'guaruclean.directives.contatoCliente',
    'guaruclean.directives.usuarioLogado',
    'guaruclean.directives.quantidadeRecados',
    'guaruclean.directives.quantidadeChamados',
    'guaruclean.directives.sidebar',
    'guaruclean.directives.classicEditor',
    'guaruclean.directives.uploadPic'
]

angular.module('guaruclean.directives', $inject)