import angular from 'angular'
import path from 'path'

angular.module('guaruclean.routes.usuario', [])

.config(function ($stateProvider) {

    $stateProvider

    .state({
        name: 'login',
        url: '/login',
        controller: 'usuarioCtrl',
        templateUrl: './../views/login.html'
    })

    .state({
        name: 'registrar',
        url: '/registrar',
        controller: 'empresaCtrl',
        templateUrl: './../views/registrar.html'
    })

})
