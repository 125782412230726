import angular from 'angular'

angular.module('guaruclean.directives.ddEstado', [])

.directive('ddEstado', [ 'commonAPI', function(commonAPI) {
    return {
        restrict: 'E',
        scope: {
            current: '=ngModel',
            type: '@'
        },
        template: `<select id="ddEstado" ng-model="current" ng-options="item.estadoId as item.estadoNome for item in Estados" class="form-control">
                   </select>`,
        controller: [ '$scope', '$rootScope', function($scope, $rootScope) {

            $scope.unlockCidades = () => {
                $rootScope.$emit('unlockCidades', { 
                    estadoId: $scope.current
                 })
            }
            
        }],
        link: function (scope, element, attrs) {

            scope.$watch('current', function(oldVal, newVal) {
                if (oldVal) {
                    scope.unlockCidades()
                }
            })

            if (scope.type === 'config') {

                commonAPI.BuscarEstados()
                    .then(resp => {
                        scope.$apply(() => {
                            scope.Estados = resp.data
                        })
                    })
                    .catch(err => {
                        console.log(err)
                    })

            } else {

                commonAPI.BuscarEmpresaEstados()
                    .then(resp => {
                        scope.$apply(() => {
                            scope.Estados = resp.data
                        })
                    })
                    .catch(err => {
                        console.log(err)
                    })

            }


        }

    }
}])