import angular from 'angular'

import Usuario        from './usuario'
import Empresa        from './empresa'
import Recados        from './recados'
import Common         from './common'
import Clientes       from './clientes'
import Pagamento      from './pagamento'
import Servico        from './servico'
import Chamados       from './chamados'
import Configuracoes  from './configuracoes'
import funcionarios   from './funcionarios'
import agenda         from './agenda'


let $inject = [
    'guaruclean.services.usuario',
    'guaruclean.services.empresa',
    'guaruclean.services.recados',
    'guaruclean.services.common',
    'guaruclean.services.clientes',
    'guaruclean.services.pagamento',
    'guaruclean.services.servicos',
    'guaruclean.services.chamados',
    'guaruclean.services.funcionarios',
    'guaruclean.services.configuracoes',
    'guaruclean.services.agenda'
]

angular.module('guaruclean.services',
    $inject)
