import angular from 'angular'

angular.module('guaruclean.directives.classicEditor', [])

.directive('classicEditor', [function() {
    return {
        restrict: 'A',
        require: 'ngModel',
        scope: {
            model: '=ngModel',
            name: '='
        },
        link: function (scope, element, attrs, ngModel) {

            $(document).ready(function(){
                
                var ck = window.CKEDITOR.replace(element[0],
                    window.CKEDITOR.editorConfig
                )

                ck.on('change', function() {
                    ngModel.$setViewValue(this.getData())
                })

                ck.on('instanceReady', function() {
                    this.setData(ngModel.$viewValue)
                })

            })

        }

    }
}])