import angular from 'angular'

import usuarioCtrl from './usuario'
import empresaCtrl from './empresa'
import clientesCtrl from './clientes'
import chamadosCtrl from './chamados'
import recadosCtrl from './recados'
import configuracoesCtrl from './configuracoes'
import agendaCtrl from './agenda'
import funcionariosCtrl from './funcionarios'
import appCtrl from './app'


let $inject = [
    'guaruclean.controllers.usuario',
    'guaruclean.controllers.empresa',
    'guaruclean.controllers.clientes',
    'guaruclean.controllers.chamados',
    'guaruclean.controllers.recados',
    'guaruclean.controllers.configuracoes',
    'guaruclean.controllers.agenda',
    'guaruclean.controllers.funcionarios',
    'guaruclean.controllers.app'
]

angular.module('guaruclean.controllers', $inject)
