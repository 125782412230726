import angular from 'angular'

angular.module('guaruclean.directives.quantidadeChamados', [])

.directive('quantidadeChamados', ['chamadosAPI', '$rootScope', function(chamadosAPI, $rootScope) {
    return {
        restrict: 'E',
        template: '<span ng-if="quantidade > 0" class="badge badge-pill badge-danger">{{quantidade}}</span>',
        scope: {
            
        },
        link: function (scope, element, attrs) {

            let buscaChamadosCount = () => {
                chamadosAPI.NovosChamados()
                .then(resp => {
                    scope.$apply(() => {
                        scope.quantidade = resp.data.length
                    })
                })
                .catch(err => {
                    console.log(err)
                })
            }

            $rootScope.$on('refreshChamadosCount', () => {
                buscaChamadosCount()
            })

            buscaChamadosCount()

        }

    }
}])