import angular from 'angular'
import path from 'path'

angular.module('guaruclean.routes.app', [])

.config(function ($stateProvider) {

    $stateProvider
    
    .state({
        name: 'app',
        abstract: true,
        controller: 'appCtrl',
        templateUrl: './../views/master.html',
        data: {
            label: 'Inicio',
            needAuth: true
        }
    })

})