import angular from 'angular'

angular.module('guaruclean.directives.quantidadeRecados', [])

.directive('quantidadeRecados', ['recadosAPI', '$rootScope', function(recadosAPI, $rootScope) {
    return {
        restrict: 'E',
        template: '<span ng-if="quantidade > 0" class="badge badge-pill badge-danger">{{quantidade}}</span>',
        scope: {
            
        },
        link: function (scope, element, attrs) {

            let buscaRecadosCount = () => {
                recadosAPI.NovosRecados()
                .then(resp => {
                    scope.$apply(() => {
                        scope.quantidade = resp.data.length
                    })
                })
                .catch(err => {
                    console.log(err)
                })
            }

            $rootScope.$on('refreshRecadosCount', () => {
                buscaRecadosCount()
            })
            
            buscaRecadosCount()
        }

    }
}])