import angular from 'angular'
import axios from 'axios'

angular.module('guaruclean.services.empresa', [])

.service('empresaAPI', ['$rootScope', '$http', function($rootScope, $http){

    this.endpoint = axios.create({
        baseURL: `//${window.location.host}/api/`,
        timeout: 10000,
        headers: {
            'x-access-token': ( $rootScope.usuario ? $rootScope.usuario.token : null )
        }
    })

    this.Registrar = dados => {
        return this.endpoint.post('empresa/registrar', dados)
    }

    return this

}])
