import angular from 'angular'
import swal from 'sweetalert'

angular.module('guaruclean.controllers.recados', [])
.controller('recadosCtrl',['$scope', '$rootScope', '$state', '$q', '$filter', 'recadosAPI', 'NgTableParams', function ($scope, $rootScope, $state, $q, $filter, recadosAPI, NgTableParams) {

   $scope.Recados = []

   $scope.Lista = new NgTableParams({
    total: 10,
    page: 1
   }, {
    getData: function(params){
        return recadosAPI.Pesquisar($scope.filtro).then(function(resp){
            let qtdTotal   = resp.data.length
            $scope.Recados = resp.data
            $scope.Recados = params.sorting() ? $filter('orderBy')($scope.Recados, params.orderBy()) : $scope.Recados
            $scope.Recados = $scope.Recados.slice((params.page() - 1) * params.count(), params.page() * params.count())
            
            params.total(qtdTotal)
            return $scope.Recados
        })
    }})

   $scope.Pesquisar = (filtro) => {
    $scope.Lista = new NgTableParams({
        total: 10,
        page: 1
        }, {
            getData: function(params){
            return recadosAPI.Pesquisar(filtro).then(function(resp){
                let qtdTotal   = resp.data.length
                $scope.Recados = resp.data
                $scope.Recados = params.sorting() ? $filter('orderBy')($scope.Recados, params.orderBy()) : $scope.Recados
                $scope.Recados = $scope.Recados.slice((params.page() - 1) * params.count(), params.page() * params.count())

                params.total(qtdTotal)
                return $scope.Recados
            })
        }})
   }

   $scope.Selecionar = () => {
       if ($state.params.recadoId > 0) {
        recadosAPI.Selecionar($state.params.recadoId)
            .then(resp => {
                $scope.$apply(function() {
                    $scope._prevRecado  = angular.copy(resp.data)
                    $scope.recado = resp.data
                })
            })
            .catch(err => {
                console.log(err)
            })
       }
   }

   $scope.Upsert = (recado) => {
       recadosAPI.Upsert(recado)
        .then(resp => {
            console.log(resp)
            if (resp.status === 200) {
                swal("Sucesso!", `Seu recado foi ${ recado.usuarioId > 0 ? 'atualizado' : 'inserido' } com sucesso!`, "success")
                    .then(resp => {
                        if (resp) {
                            $rootScope.$emit('refreshRecadosCount')
                            $state.go('app.recados.lista')
                        }
                    })
                    .catch(err => {
                        console.log(err)
                    })
            }
        })
        .catch(err => {
            console.log(err)
        })
   }

   $scope.Deletar = (recado) => {
    swal({
        title: "Tem certeza que deseja deletar?",
        text: "Uma vez deletado, o registro não poderá mais ser recuperado.",
        icon: "warning",
        buttons: true,
        dangerMode: true,
        })
        .then((willDelete) => {
        if (willDelete) {

            recadosAPI.Deletar(recado.recadoId)
                .then(resp => {
                    if (resp.status === 200) {
                        swal("Sucesso!", `Seu recado foi deletado com sucesso!`, "success")
                        .then(resp => {
                            if (resp) {
                                $rootScope.$emit('refreshRecadosCount')
                                $state.go('app.recados.lista')
                            }
                        })
                        .catch(err => {
                            console.log(err)
                        })
                    }
                })
                .catch(err => {
                    swal("Erro!", `Occoreu um erro inesperado! Tente novamente em instantes.`, "error")
                        .then(resp => {
                            if (resp) {
                                $state.go('app.recados.lista')
                            }
                        })
                        .catch(err => {
                            console.log(err)
                        })
                })

        } else {
            return false
        }
        })
}

   $scope.init = () => {
       
   }

   $scope.init()

}])
