import angular from 'angular'
import path from 'path'

angular.module('guaruclean.routes.agenda', [])

.config(function ($stateProvider) {

    $stateProvider

    .state({
        name: 'app.agenda',
        url: '/agenda',
        templateUrl: './../views/agenda/agenda.html',
        controller: 'agendaCtrl',
        data: {
            label: 'Agenda'
        }
    })

})