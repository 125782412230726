import angular from 'angular'
import fileSaver from 'file-saver'
import introJs from 'intro.js'

angular.module('guaruclean.controllers.chamados', [])
.controller('chamadosCtrl',['$scope', '$rootScope', '$state', '$timeout', '$filter', 'NgTableParams', 'servicoAPI', 'chamadosAPI', function ($scope, $rootScope, $state, $timeout, $filter, NgTableParams, servicoAPI, chamadosAPI) {

   $scope.dados = {}
   $scope.Chamados = []

   $scope.Lista = new NgTableParams({
        total: 10,
        page: 1
    }, {
    getData: function(params){
        return chamadosAPI.Pesquisar($scope.filtro).then(function(resp){
            let qtdTotal    = resp.data.length
            $scope.Chamados = resp.data
            $scope.Chamados = params.sorting() ? $filter('orderBy')($scope.Chamados, params.orderBy()) : $scope.Chamados
            $scope.Chamados = $scope.Chamados.slice((params.page() - 1) * params.count(), params.page() * params.count())

            params.total(qtdTotal)
            return $scope.Chamados
        })
    }})

    $scope.Pesquisar = (filtro) => {
        $scope.Lista = new NgTableParams({
            total: 10,
            page: 1
        }, {
        getData: function(params){
            return chamadosAPI.Pesquisar(filtro).then(function(resp){
                let qtdTotal    = resp.data.length
                $scope.Chamados = resp.data
                $scope.Chamados = params.sorting() ? $filter('orderBy')($scope.Chamados, params.orderBy()) : $scope.Chamados
                $scope.Chamados = $scope.Chamados.slice((params.page() - 1) * params.count(), params.page() * params.count())

                params.total(qtdTotal)
                return $scope.Chamados
            })
        }})
    }

   $scope.Selecionar = () => {
        if ($state.params.chamadoId > 0) {
            chamadosAPI.Selecionar($state.params.chamadoId)
                .then(resp => {
                    $scope.$apply(function() {
                        $scope._prevChamado  = angular.copy(resp.data)
                        $scope.dados    = resp.data

                        let _servicos = {}
                        resp.data.servico_chamados.map((item, index) => {
                            if (item.servicoId === null) {
                                _servicos['outros'] = true
                                _servicos['outrosDescricao'] = item.observacao
                            } else {
                                _servicos[item.servicoId] = true
                            }
                        })

                        $timeout(function() {
                            $scope.$apply(() => {
                                $scope.dados.servico_chamados = _servicos
                            })
                        }, 500)

                    })
                })
                .catch(err => {
                    console.log(err)
                })
        }
    }

    $scope.Upsert = (chamado) => {
        chamadosAPI.Upsert(chamado)
            .then(resp => {
                if (resp.status === 200) {
                    swal("Sucesso!", `Seu cliente foi ${ chamado.chamadoId > 0 ? 'atualizado' : 'inserido' } com sucesso!`, "success")
                    .then(resp => {
                        if (resp) {
                            $rootScope.$emit('refreshChamadosCount')
                            $state.go('app.chamados.lista')
                        }
                    })
                    .catch(err => {
                        console.log(err)
                    })
                }
            })
            .catch(err => {
                swal("Erro!", `Houve um erro inesperado! Tente novamente em alguns instantes`, "error")
                    .then(resp => {
                        if (resp) {
                            $state.go('app.chamados.lista')
                        }
                    })
                    .catch(err => {
                        console.log(err)
                    })
            })
   }

   $scope.buscarServicos = () => {
       servicoAPI.PesquisarEmpresaServico()
        .then(resp => {
            $scope.$apply(() => {
                $scope.Servicos = resp.data
            })
        })
        .catch(err => console.log(err))
   }

   $scope.addContato = (contato) => {
        if (!contato) return false;

        if ($scope.dados.contato_chamados) {

            let exist = _.find($scope.dados.contato_chamados, contato )
            if (!exist) {
                $scope.dados.contato_chamados.push(contato)
                delete $scope.novoContato
            }

        } else {
            $scope.dados.contato_chamados = [
                contato
            ]
            delete $scope.novoContato
        }
    }

    $scope.removeContato = (contato) => {
        if (!contato) return false;

        swal({
            title: "Tem certeza que deseja deletar?",
            text: "Uma vez deletado, o registro não poderá mais ser recuperado.",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
        .then((willDelete) => {
            if (willDelete) {

                let ctt = _.findIndex($scope.dados.contato_chamados, contato)
                if(ctt != -1) {
                    $scope.$apply(function() {
                        if ($scope.dados.contato_chamados.length > 1) {
                            _.remove($scope.dados.contato_chamados, contato)
                        } else {
                            $scope.dados.contato_chamados = []
                        }
                    })
                }

            } else {
            return false
            }
        });

    }

    $scope.Deletar = (chamado) => {
        swal({
            title: "Tem certeza que deseja deletar?",
            text: "Uma vez deletado, o registro não poderá mais ser recuperado.",
            icon: "warning",
            buttons: true,
            dangerMode: true,
            })
            .then((willDelete) => {
            if (willDelete) {

                chamadosAPI.Deletar(chamado.chamadoId)
                    .then(resp => {
                        if (resp.status === 200) {
                            swal("Sucesso!", `Seu chamado foi deletado com sucesso!`, "success")
                            .then(resp => {
                                if (resp) {
                                    $state.go('app.chamados.lista')
                                }
                            })
                            .catch(err => {
                                console.log(err)
                            })
                        }
                    })
                    .catch(err => {
                        swal("Erro!", `Occoreu um erro inesperado! Tente novamente em instantes.`, "error")
                            .then(resp => {
                                if (resp) {
                                    $state.go('app.chamados.lista')
                                }
                            })
                            .catch(err => {
                                console.log(err)
                            })
                    })

            } else {
                return false
            }
            })
    }

   $scope.Imprimir = async (chamadoId) => {
      try {
        let file = await chamadosAPI.Imprimir(chamadoId)
        let blob = new Blob([file.data], { type: file.headers["content-type"] });
        fileSaver.saveAs(blob, file.headers['x-filename']);
      } catch (e) {
          console.log(e)
      }
   }

   $scope.init = () => {
       $scope.buscarServicos()

       $(document).ready(function() {

         // var intro = new introJs();
         //
         // intro.addStep({
         //   hint: 'teste',
         //   intro: "Seja bem-vindo ao DDsys! Vamos guiá-lo pelo sistema e apresentar algumas funcionalidades. Vamos lá?",
         //   position: 'auto'
         // });
         //
         // intro.addStep({
         //   element: document.getElementsByClassName('main-nav')[0],
         //   hint: 'teste',
         //   intro: "Ok, wasn't that fun?",
         //   position: 'auto'
         // });
         //
         // intro.addStep({
         //   element: document.getElementsByClassName("btn-info")[0],
         //   intro: "Ok, wasn't that fun?",
         //   position: 'right'
         // });
         //
         // intro.addStep({
         //   element: document.getElementsByTagName("table")[0],
         //   intro: "Ok, wasn't that fun?",
         //   position: 'top'
         // });
         //
         // intro.addHints();
         //
         // console.log(intro)
         //
         // intro.start();

       })

   }

   $scope.init()

}])
