import angular from 'angular'

angular.module('guaruclean.directives.sidebar', [])

.directive('sidebarNav', ['recadosAPI', '$cookies', function(recadosAPI, $cookies) {
    return {
        restrict: 'E',
        templateUrl: './directives/view/sidebar.html',
        scope: {

        },
        link: function (scope, element, attrs) {

            scope.Usuario = JSON.parse($cookies.get("__grclean-usu"))

            $(document).ready(function() {
                $('#sidebarCollapse').on('click', function () {
                    $('#sidebar').toggleClass('active');
                });
            });

        }

    }
}])
