import angular from 'angular'
import axios from 'axios'

angular.module('guaruclean.services.configuracoes', [])

.service('configuracoesAPI', ['$rootScope', '$http', '$cookies', function($rootScope, $http, $cookies){

    this.endpoint = axios.create({
        baseURL: `//${window.location.host}/api/`,
        timeout: 5000,
        headers: { 
            'x-access-token': ( $cookies.get('__grclean-tkn') ? $cookies.get('__grclean-tkn') : null )
        }
    })

    this.PesquisarEmpresa = (dados) => {
        return this.endpoint.post('/configuracoes/empresa/', dados)
    }

    this.SelecionarEmpresa = (id) => {
        return this.endpoint.get('/configuracoes/empresa/', {
            params: {
                empresaId: id
            }
        })
    }

    this.UpsertEmpresa = (dados) => {
        return this.endpoint.put('/configuracoes/empresa/', {
            params: {
                empresaId: dados.empresaId
            },
            data: dados
        })
    }

    this.PesquisarTema = () => {
        return this.endpoint.get('/configuracoes/tema/')
    }

    return this

}])