import angular from 'angular'
import path from 'path'

angular.module('guaruclean.routes.configuracoes', [])

.config(function ($stateProvider) {

    $stateProvider

    .state({
        name: 'app.configuracoes',
        abstract: true,
        url: '/configuracoes',
        templateUrl: './../views/configuracoes/configuracoes.html',
        data: {
            label: 'Configurações'
        }
    })

    .state({
        name: 'app.configuracoes.empresa',
        controller: 'configuracoesCtrl',
        url: '/empresa',
        templateUrl: './../views/configuracoes/empresa.html',
        data: {
            label: 'Empresa'
        }
    })

    .state({
        name: 'app.configuracoes.usuario',
        controller: 'configuracoesCtrl',
        url: '/usuario',
        templateUrl: './../views/configuracoes/usuario.html',
        data: {
            label: 'Usuário'
        }
    })

    .state({
        name: 'app.configuracoes.usuarios',
        controller: 'configuracoesCtrl',
        url: '/usuarios',
        templateUrl: './../views/configuracoes/usuarios.html',
        data: {
            label: 'Usuários'
        }
    })

})