import angular from 'angular'
import axios from 'axios'

angular.module('guaruclean.services.clientes', [])

.service('clientesAPI', ['$rootScope', '$http', '$cookies', function($rootScope, $http, $cookies){

    this.endpoint = axios.create({
        baseURL: `//${window.location.host}/api/`,
        timeout: 5000,
        headers: { 
            'x-access-token': ( $cookies.get('__grclean-tkn') ? $cookies.get('__grclean-tkn') : null )
        }
    })

    this.Pesquisar = (dados) => {
        return this.endpoint.post('cliente/', dados)
    }

    this.Selecionar = (id) => {
        return this.endpoint.get('cliente/', {
            params: {
                clienteId: id
            }
        })
    }

    this.Upsert = (dados) => {
        return this.endpoint.put('cliente/', {
            params: {
                clienteId: dados.clienteId
            },
            data: dados
        })
    }

    this.Deletar = (id) => {
        return this.endpoint.delete('cliente/', {
            params: {
                clienteId: id
            }
        })
    }

    return this

}])