import angular from 'angular'

angular.module('guaruclean.controllers.usuario', [])
.controller('usuarioCtrl',['$scope', '$rootScope', '$state', '$cookies', '$timeout', 'usuarioAPI', 'toastr', function ($scope, $rootScope, $state, $cookies, $timeout, usuarioAPI, toastr) {

   $scope.Entrar = async (dados) => {

     if (!dados || !dados.email || !dados.senha) return false;

     $timeout(function() {
        $scope.$apply(() => {
            $scope.loading = true
        })
     }, 500);

     try {

       let usuarioReq = await usuarioAPI.Entrar(dados);
       if (usuarioReq.status === 200) {

         let { tema } = usuarioReq.data.usuario.empresa;

         $rootScope.Autenticado   = usuarioReq.data.auth
         $rootScope.usuario       = usuarioReq.data.usuario
         $rootScope.usuario.token = usuarioReq.data.token

         $cookies.put('__grclean-usu', JSON.stringify(usuarioReq.data.usuario))
         $cookies.put('__grclean-tkn', usuarioReq.data.token)

         document.getElementsByTagName('body')[0]
             .classList
             .add(tema.nome || 'green-theme')

         $state.go('app.chamados.lista')
         $timeout(function() {
             $scope.$apply(() => {
                 $scope.loading = false
             })
          }, 500)
       }

     } catch (e) {
       $timeout(function() {
           $scope.$apply(() => {
               $scope.loading = false
               let { title, description } = e.response.data.error;
               toastr.error(description, title, {
                 closeButton: true,
                 progressBar: true
               })
           })
        }, 500)
     }

   }

   $scope.Registrar = async dados => {
     console.log(this);
     let usuarioReq, { data } = await usuarioAPI.Registrar(dados);

     console.log(data, usuarioReq);
   }

   $scope.init = () => {
     $(document).ready(function(){
      $(".owl-carousel").owlCarousel({
        items: 1,
        loop: true,
        nav: false,
        autoplay: true
      });
    });
   }

   $scope.init()

}])
