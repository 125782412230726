import angular from 'angular'

angular.module('guaruclean.directives.usuarioLogado', [])

.directive('usuarioLogado', ['usuarioAPI', '$state', '$cookies', function(usuarioAPI, $state, $cookies) {
    return {
        restrict: 'E',
        templateUrl: './directives/view/usuario-logado.html',
        scope: {
            
        },
        link: function (scope, element, attrs) {
            $(".dropdown-toggle").dropdown()

            scope.Usuario = JSON.parse($cookies.get("__grclean-usu"))

            scope.logout = () => {
                $cookies.remove("__grclean-tkn")
                $cookies.remove("__grclean-usu")
                $state.go("login")
            }
        }

    }
}])