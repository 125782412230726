import 'angular'
import 'jquery'
import 'popper.js'
import '@uirouter/angularjs'
import 'angular-sanitize'
import 'angular-cookies'
import 'ng-table'
import 'ui-router-breadcrumbs'
import 'moment'
import 'reflect-metadata'
import 'angular-animate'
import 'angular-ui-bootstrap'
import '@fortawesome/fontawesome-free'
import 'angular-input-masks'
import 'angular-br-filters'
import 'ng-file-upload'
import 'fullcalendar'
import 'fullcalendar-scheduler'
import 'fullcalendar/dist/locale-all'
import 'angular-toastr'

import 'bootstrap/dist/js/bootstrap.min.js'
import './dist/owl.carousel.min.js'
import 'bootstrap/dist/css/bootstrap.css'
import 'angular-ui-bootstrap/dist/ui-bootstrap-csp.css'
import 'fullcalendar/dist/fullcalendar.css'
import 'flatpickr/dist/flatpickr.css'
import 'flatpickr/dist/themes/confetti.css'
import 'angular-toastr/dist/angular-toastr.css'

import './assets/css/clean.css'

let $inject = [
    'ui.router',
    'ngAnimate',
    'ngCookies',
    'ngTable',
    'ui.bootstrap',
    'ui.utils.masks',
    'idf.br-filters',
    'uiBreadcrumbs',
    'ngFileUpload',
    'toastr',
    'guaruclean.services',
    'guaruclean.controllers',
    'guaruclean.routes',
    'guaruclean.directives'
]

angular.module('guaruclean', $inject)
    .config(['$httpProvider', function($httpProvider) {

    function Interceptor() {
        return {
            request: function(config) {
                $('.loading').css('display', 'flex');
                return config;
            },
            response: function(response) {
                $('.loading').css('display', 'none');
                return response;
            },
            responseError: function(error) {
                if (error.status === 401 || error.status === 403) {

                }
            }
        };
    }

    $httpProvider.interceptors.push(Interceptor);

    }])
    .run([ '$rootScope', '$location', '$state', '$cookies', '$timeout', 'commonAPI', function($rootScope, $location, $state, $cookies, $timeout, commonAPI) {

        window.onload = async () => {

            // let location = window.location.hostname
            // let empresa  = location.split('.')[0]
            //
            // let dadosEmpresa = await commonAPI.BuscarDadosEmpresa(empresa);
            //
            // document.getElementsByTagName('body')[0]
            //     .classList
            //     .add(dadosEmpresa.temaNome || 'green-theme')

        }

        $rootScope.$on('$viewContentLoading', function (event, toState, toParams, fromState, fromParams) {

            var Autenticado = $cookies.get('__grclean-tkn')
            if (!Autenticado) {
                if($state.current.name !== 'registrar') {
                  $state.go("login")
                }
            }

            $timeout(function(){
                $rootScope.$apply(function() {
                    $rootScope.currentState = $state.current.name
                })
            }, 100)

        })

    }])

import services from './services'
import controllers from './controllers'
import routes from './routes'
import directives from './directives'
