import angular from 'angular'
import path from 'path'

angular.module('guaruclean.routes.funcionarios', [])

.config(function ($stateProvider) {

    $stateProvider

    .state({
        name: 'app.funcionarios',
        abstract: true,
        url: '/funcionarios',
        template: '<ui-view></ui-view>',
        data: {
            label: 'Funcionários'
        }
    })

    .state({
        name: 'app.funcionarios.lista',
        controller: 'funcionariosCtrl',
        url: '/lista',
        templateUrl: './../views/funcionarios/funcionarios.html',
        data: {
            label: 'Lista'
        }
    })

    .state({
        name: 'app.funcionarios.novo',
        controller: 'funcionariosCtrl',
        url: '/novo',
        templateUrl: './../views/funcionarios/funcionarios-form.html',
        data: {
            label: 'Novo'
        }
    })

    .state({
        name: 'app.funcionarios.form',
        controller: 'funcionariosCtrl',
        url: '/editar/:funcionarioId',
        templateUrl: './../views/funcionarios/funcionarios-form.html',
        data: {
            label: 'Editar'
        }
    })

})