import angular from 'angular'

angular.module('guaruclean.controllers.empresa', [])
.controller('empresaCtrl',['$scope', '$rootScope', '$state', '$cookies', '$timeout', 'empresaAPI', 'toastr', function ($scope, $rootScope, $state, $cookies, $timeout, empresaAPI, toastr) {

   $scope.Registrar = async dados => {

     try {
       let usuarioReq, { data, status } = await empresaAPI.Registrar(dados);
       console.log(status, data, usuarioReq)
       if (status === 200) {

         let { title, description } = data.payload;
         toastr.success(description, title, {
           closeButton: true,
           progressBar: true
         })

         $timeout(function() {
           $state.go('login');
         }, 2000);
       }

     } catch (e) {
       console.log(e);
     }

   }

   $scope.init = () => {
     $(document).ready(function(){
      $(".owl-carousel").owlCarousel({
        items: 1,
        loop: true,
        nav: false,
        autoplay: true
      });
    });
   }

   $scope.init()

}])
