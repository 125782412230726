import angular from 'angular'
import axios from 'axios'

angular.module('guaruclean.services.common', [])

.service('commonAPI', ['$rootScope', '$http', '$cookies', function($rootScope, $http, $cookies){

    this.endpoint = axios.create({
        baseURL: `//${window.location.host}/api/`,
        timeout: 5000,
        headers: { 
            'x-access-token': ( $cookies.get('__grclean-tkn') ? $cookies.get('__grclean-tkn') : null )
        }
    })

    this.BuscarEstados = () => {
        return this.endpoint.get('common/estados')
    }

    this.BuscarEmpresaEstados = () => {
        return this.endpoint.get('common/empresa-estados')
    }

    this.BuscarCidades = (estadoId) => {
        return this.endpoint.get('common/cidades', {
            params: {
                estadoId
            }
        })
    }

    this.BuscarDadosEmpresa = (dominio) => {
        return this.endpoint.get('common/dados-empresa', {
            params: {
                dominio: dominio
            }
        })
    }

    return this

}])