import angular from 'angular'
import _ from 'lodash'

angular.module('guaruclean.controllers.clientes', [])
.controller('clientesCtrl',['$scope', '$rootScope', '$state', '$timeout', '$filter', 'clientesAPI', 'pagamentoAPI', 'servicoAPI', 'NgTableParams', function ($scope, $rootScope, $state, $timeout, $filter, clientesAPI, pagamentoAPI, servicoAPI, NgTableParams) {

    $scope.dados = {}
    $scope.Clientes = []

    $scope.Lista = new NgTableParams({
        total: 10,
        page: 1
    }, {
    getData: function(params){
        return clientesAPI.Pesquisar($scope.filtro).then(function(resp){
            let qtdTotal    = resp.data.length
            $scope.Clientes = resp.data
            $scope.Clientes = params.sorting() ? $filter('orderBy')($scope.Clientes, params.orderBy()) : $scope.Clientes
            $scope.Clientes = $scope.Clientes.slice((params.page() - 1) * params.count(), params.page() * params.count())

            params.total(qtdTotal)
            return $scope.Clientes
        })
    }})

    $scope.Pesquisar = (filtro) => {
        console.log("click")
        $scope.Lista = new NgTableParams({
            total: 10,
            page: 1
        }, {
            getData: function(params){
            return clientesAPI.Pesquisar(filtro).then(function(resp){
                let qtdTotal    = resp.data.length
                $scope.Clientes = resp.data
                $scope.Clientes = params.sorting() ? $filter('orderBy')($scope.Clientes, params.orderBy()) : $scope.Clientes
                $scope.Clientes = $scope.Clientes.slice((params.page() - 1) * params.count(), params.page() * params.count())

                params.total(qtdTotal)
                return $scope.Clientes
            })
        }})
    }

    $scope.Selecionar = () => {
        if ($state.params.clienteId > 0) {
            clientesAPI.Selecionar($state.params.clienteId)
                .then(resp => {
                    $scope.$apply(function() {
                        $scope.dados = resp.data
                        $scope.buscarServicos()
                        atualizarServicosDisponiveis()
                    })
                })
                .catch(err => {
                    console.log(err)
                })
        }
    }

    $scope.Upsert = (cliente) => {
        clientesAPI.Upsert(cliente)
            .then(resp => {
                if (resp.status === 200) {
                    swal("Sucesso!", `Seu cliente foi ${ cliente.clienteId > 0 ? 'atualizado' : 'inserido' } com sucesso!`, "success")
                    .then(resp => {
                        if (resp) {
                            $state.go('app.clientes.lista')
                        }
                    })
                    .catch(err => {
                        console.log(err)
                    })
                }
            })
            .catch(err => {
                swal("Erro!", `Houve um erro inesperado! Tente novamente em alguns instantes`, "error")
                    .then(resp => {
                        if (resp) {
                            $state.go('app.clientes.lista')
                        }
                    })
                    .catch(err => {
                        console.log(err)
                    })
            })
    }

    $scope.buscarPagamentos = () => {
        pagamentoAPI.Pesquisar()
            .then(resp => {
                $scope.Pagamento = resp.data
            })
            .catch(err => {
                console.log(err)
            })
    }

    $scope.buscarServicos = () => {
        servicoAPI.PesquisarEmpresaServico()
            .then(resp => {
                $scope.Servicos = resp.data
                $scope.ServicosDisponiveis = $scope.Servicos.filter((item) => {
                    if(_.findIndex($scope.dados.servico_clientes, { servicoId: item.servicoId }) === -1) {
                        return item
                    }
                })
            })
            .catch(err => {
                console.log(err)
            })
    }

    var atualizarServicosDisponiveis = () => {
        $scope.ServicosDisponiveis = $scope.Servicos.filter((item) => {
            if(_.findIndex($scope.dados.servico_clientes, { servicoId: item.servicoId }) === -1) {
                return item
            }
        })
    }

    $scope.addContato = (contato) => {
        if (!contato) return false;

        if ($scope.dados.contato_clientes) {

            let exist = _.find($scope.dados.contato_clientes, { email: contato.email } )
            if (!exist) {
                $scope.dados.contato_clientes.push(contato)
                delete $scope.novoContato
            }

        } else {
            $scope.dados.contato_clientes = [
                contato
            ]
            delete $scope.novoContato
        }
    }

    $scope.removeContato = (contato) => {
        if (!contato) return false;

        swal({
            title: "Tem certeza que deseja deletar?",
            text: "Uma vez deletado, o registro não poderá mais ser recuperado.",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          })
          .then((willDelete) => {
            if (willDelete) {

                let ctt = _.findIndex($scope.dados.contato_clientes, contato)
                if(ctt != -1) {
                    $scope.$apply(function() {
                        if ($scope.dados.contato_clientes.length > 1) {
                            _.remove($scope.dados.contato_clientes, contato)
                        } else {
                            $scope.dados.contato_clientes = []
                        }
                    })
                }

            } else {
              return false
            }
          });

    }

    $scope.addFrequencia = (frequencia) => {
        if (!frequencia) return false;

        if ($scope.dados.frequencia_clientes) {

            let exist = _.find($scope.dados.frequencia_clientes, frequencia )
            if (!exist) {
                $scope.dados.frequencia_clientes.push(frequencia)
                delete $scope.novaFrequencia
            }

        } else {
            $scope.dados.frequencia_clientes = [
                frequencia
            ]
            delete $scope.novaFrequencia
        }
    }

    $scope.removeFrequencia = (frequencia) => {
        if (!frequencia) return false;

        swal({
            title: "Tem certeza que deseja deletar?",
            text: "Uma vez deletado, o registro não poderá mais ser recuperado.",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          })
          .then((willDelete) => {
            if (willDelete) {

                let ctt = _.findIndex($scope.dados.frequencia_clientes, frequencia)
                if(ctt > -1) {
                    $scope.$apply(() => {
                        if ($scope.dados.frequencia_clientes.length > 1) {
                            _.remove($scope.dados.frequencia_clientes, frequencia)
                        } else {
                            $scope.dados.frequencia_clientes = []
                        }
                    })
                }

            } else {
              return false
            }
          });
    }

    $scope.addNovoServico = (servico) => {
        if (!servico) return false;

        if ($scope.dados.servico_clientes) {
            let svc = _.findIndex($scope.dados.servico_clientes, { servicoId: servico.servicoId })
            if (svc === -1) {
                
                servico["servico"] = {
                    nome: _.filter($scope.Servicos, { servicoId: servico.servicoId })[0].servico.nome
                }

                $timeout(function(){
                    $scope.$apply(function() {
                        $scope.dados.servico_clientes.push(servico)
                        atualizarServicosDisponiveis()
                        // $('#addServico').modal('toggle')
                        $scope.closeModal('#addServico')
                        delete $scope.novoServico
                    })
                }, 500)
                
            } else {
                $timeout(function(){
                    $scope.$apply(function() {
                        $('#addServico').modal('toggle')
                        $scope.closeModal('#addServico')
                        delete $scope.novoServico
                    })
                }, 500)
            }

        } else {
            $timeout(function(){
                $scope.$apply(function() {
                    $scope.dados.servico_clientes = [ servico ]
                    delete $scope.novoServico
                })
            }, 500)
        }

    }

    $scope.removeServico = (servico) => {
        swal({
            title: "Tem certeza que deseja deletar?",
            text: "Uma vez deletado, o registro não poderá mais ser recuperado.",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          })
          .then((willDelete) => {
            if (willDelete) {

                let svc = _.findIndex($scope.dados.servico_clientes, { servicoId: servico.servicoId })
                if(svc > -1) {
                    $scope.$apply(function() {
                        if ($scope.dados.servico_clientes.length > 1) {
                            _.remove($scope.dados.servico_clientes, {
                                servicoId: servico.servicoId
                            })
                        } else {
                            $scope.dados.servico_clientes = []
                        }
                        atualizarServicosDisponiveis()
                    })
                }

            } else {
              return false
            }
          })
    }

    $scope.Deletar = (cliente) => {
        swal({
            title: "Tem certeza que deseja deletar?",
            text: "Uma vez deletado, o registro não poderá mais ser recuperado.",
            icon: "warning",
            buttons: true,
            dangerMode: true,
            })
            .then((willDelete) => {
            if (willDelete) {

                clientesAPI.Deletar(cliente.clienteId)
                    .then(resp => {
                        if (resp.status === 200) {
                            swal("Sucesso!", `Seu cliente foi deletado com sucesso!`, "success")
                            .then(resp => {
                                if (resp) {
                                    $state.go('app.clientes.lista')
                                }
                            })
                            .catch(err => {
                                console.log(err)
                            })
                        }
                    })
                    .catch(err => {
                        swal("Erro!", `Occoreu um erro inesperado! Tente novamente em instantes.`, "error")
                            .then(resp => {
                                if (resp) {
                                    $state.go('app.clientes.lista')
                                }
                            })
                            .catch(err => {
                                console.log(err)
                            })
                    })

            } else {
                return false
            }
            })
    }

    $scope.openModal = (nomeModal) => {
        $(nomeModal).modal('show')
    }

    $scope.closeModal = (nomeModal) => {
        $(nomeModal).modal('hide')
        $('body').removeClass('modal-open')
        $('.modal-backdrop').remove()
    }

    $scope.init = () => {
        $scope.buscarPagamentos()
        $scope.buscarServicos()
    }

    $scope.init()

}])
