import angular from 'angular'
import axios from 'axios'

angular.module('guaruclean.services.usuario', [])

.service('usuarioAPI', ['$rootScope', '$http', function($rootScope, $http){

    this.endpoint = axios.create({
        baseURL: `//${window.location.host}/api/`,
        timeout: 5000,
        headers: {
            'x-access-token': ( $rootScope.usuario ? $rootScope.usuario.token : null )
        }
    })

    this.Autenticado = $rootScope.usuario && $rootScope.Autenticado

    this.Entrar = (dados) => {
        return this.endpoint.post('usuario/entrar', dados)
    }

    this.Registrar = dados => {
        return this.endpoint.post('usuario/registrar', dados)
    }

    this.Sair = () => {
        return this.endpoint.get('usuario/sair')
    }

    return this

}])
