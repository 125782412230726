import angular from 'angular'
import flatpickr from 'flatpickr'
import Portuguese from 'flatpickr/dist/l10n/pt.js'
import Tema from 'flatpickr/dist/themes/material_green.css'

angular.module('guaruclean.directives.datePicker', [])

.directive('datePicker', [ function() {
    return {
        restrict: 'A',
        require: '^ngModel',
        scope: {
            model: '=ngModel',
            time: '='
        },
        link: function (scope, element, attrs, ngModel) {
            
            let defaultConfig = {
                dateFormat: 'd/m/Y',
                allowInput: true,
                locale: Portuguese.pt
            }

            scope.$watch('model', (newVal, oldVal) => {
                if (newVal) {
                    if (/\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d(?:\.\d+)?Z?/.test(newVal)) {
                        let _value = newVal.substr(0,10).split("-").reverse().join("/")
                        ngModel.$setViewValue(_value)
                        ngModel.$render()
                    }
                }
            })

            let customConfig = {}

            if (attrs.time === "false") {
                customConfig["enableTime"] = false
            } else {
                customConfig["enableTime"] = true
            }

            angular.extend(customConfig, defaultConfig)
            $(element).flatpickr(customConfig)
        }

    }
}])