import angular  from 'angular'
import moment   from 'moment'

angular.module('guaruclean.controllers.agenda', [])
.controller('agendaCtrl',['$scope', '$rootScope', '$state', '$cookies', '$timeout', 'toastr', 'usuarioAPI', 'agendaAPI', function ($scope, $rootScope, $state, $cookies, $timeout, toastr, usuarioAPI, agendaAPI) {

    moment.locale('pt-br')

    $scope.agendamento = {}
    $scope.Eventos     = []

    var initCalendario = (eventos) => {

        $('#agenda').fullCalendar({
            themeSystem: 'bootstrap4',
            schedulerLicenseKey: 'GPL-My-Project-Is-Open-Source',
            locale: 'pt',
            eventSources: [{
                url: 'api/agenda/',
                type: 'POST',
                headers: {
                    'x-access-token': ( $cookies.get('__grclean-tkn') ? $cookies.get('__grclean-tkn') : null )
                }
            }],
            header: {
                left: 'prev,next today',
                center: 'title',
                right: 'month,agendaWeek,agendaDay,listMonth'
            },
            navLinks: true,
            editable: true,
            eventLimit: true,
            eventRender: function(eventObj, $el) {

            },
            eventClick: function(calEvent, jsEvent, view) {

                setTimeout(function() {
                    $scope.$apply(function(){
                        $scope.selectedEvent = calEvent
                        $('#modalDetalhesEvento').modal('toggle')
                    })
                }, 500)

            },
            dayClick: (date, jsEvent, view) => {

                $scope.$apply(function() {
                    $scope.agendamento.dataInicio = date.format('DD/MM/YYYY')
                })

                $("#novoAgendamento").modal("show")

            }
            // events: eventos
        })
    }

    $scope.novoAgendamento = (agendamento) => {

        let randomColor = () => {

            let colors = ['#4DB6AC', '#FF7043','#FFC107','#3F51B5','#F44336']

            return colors[(Math.floor(Math.random() * colors.length) + 1)]
        }

        let prepareDateTime = (date, time) => {

            if (!date || !time) return ''

            let _date = date.split("/").reverse().join("-")
            let _time = time

            return _date + ' ' + _time

        }

        let _agendamento        = angular.copy(agendamento)
            _agendamento.start  = moment(prepareDateTime(_agendamento.dataInicio, _agendamento.horaInicio)).format()
            _agendamento.end    = moment(prepareDateTime(_agendamento.dataTermino, _agendamento.horaTermino)).format()
            _agendamento.title  = _agendamento.evento
            _agendamento.color  = '#81C784'

        agendaAPI.Upsert(_agendamento)
            .then(resp => {
                if (resp.status === 200) {
                    _agendamento.agendaId = resp.data.agendaId
                    $scope.Eventos.push(_agendamento)
                    $('#agenda').fullCalendar('renderEvent', _agendamento)
                    swal({ title: 'Sucesso!', text: 'Seu agendamento foi feito com sucesso!', icon: 'success' })
                    $('#novoAgendamento').modal('toggle')
                    $scope.agendamento = {}
                } else {
                    toastr.error('Erro!', 'Inesperado')
                }
            })
            .catch(err => toastr.error('Erro!', 'Inesperado'))

    }

    $scope.deletarAgendamento = (agendamento) => {

        if (agendamento.agendaId) {
            swal({
                title: "Deseja deletar?",
                text: "Uma vez deletado o evento não poderá mais ser recuperado.",
                icon: "warning",
                buttons: true,
                dangerMode: true,
              })
              .then((willDelete) => {
                if (willDelete) {

                    agendaAPI.Deletar(agendamento.agendaId)
                    .then(resp => {
                        if (resp.status === 200) {
                            $('#agenda').fullCalendar('refetchEvents', agendamento)
                            $('#modalDetalhesEvento').modal('toggle')
                            swal({ title: 'Sucesso!', text: 'Seu agendamento foi excluido com sucesso!', icon: 'success' })
                        }
                    })
                    .catch(err => {
                        console.log(err)
                        swal({ title: 'Erro!', text: 'Não foi possível deletar o agendamento. Tente novamente em instantes', icon: 'error' })
                    })

                } else {
                    swal({ title: 'Erro!', text: 'Não foi possível deletar o agendamento. Tente novamente em instantes', icon: 'error' })
                }
              })
        }

    }

    this.init = () => {

        agendaAPI.Pesquisar()
        .then(resp => {
            $scope.Eventos = resp.data
            initCalendario($scope.Eventos)
        })
        .catch(err => console.log(err))

    }

    this.init()

}])
