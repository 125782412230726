import angular from 'angular'

angular.module('guaruclean.directives.contatoCliente', [])

.directive('contatoCliente', [ 'commonAPI', function(commonAPI) {
    return {
        restrict: 'E',
        scope: {
            current: '=ngModel',
            type: '='
        },
        templateUrl: './directives/view/contato.html',
        controller: [ '$scope', '$rootScope', function($scope, $rootScope) {

            
            
        }],
        link: function (scope, element, attrs) {

            

        }

    }
}])