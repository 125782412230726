import angular from 'angular'

angular.module('guaruclean.controllers.configuracoes', [])
.controller('configuracoesCtrl',['$scope', '$rootScope', '$state', '$cookies', '$timeout', 'usuarioAPI', 'servicoAPI', 'configuracoesAPI', 'commonAPI', 'Upload', function ($scope, $rootScope, $state, $cookies, $timeout, usuarioAPI, servicoAPI, configuracoesAPI, commonAPI, Upload) {

    $scope.init = () => {

        servicoAPI.Pesquisar()
            .then(resp => $scope.Servicos = resp.data)
            .catch(err => console.log(err))

        commonAPI.BuscarEstados()
            .then(resp => $scope.Estados = resp.data)
            .catch(err => console.log(err))

        configuracoesAPI.PesquisarTema()
            .then(resp => $scope.Temas = resp.data)
            .catch(err => console.log(err))
   }

    $scope.buscaEmpresa = () => {
       let empresaId = JSON.parse($cookies.get('__grclean-usu'))["empresaId"]

        configuracoesAPI.SelecionarEmpresa(empresaId)
            .then(resp => {
                $scope.dados = resp.data

                let _servicos = {}
                resp.data.empresa_servicos.map((item, index) => {
                    _servicos[item.servicoId] = true
                })

                let _estados = {}
                resp.data.empresa_estados.map((item, index) => {
                    _estados[item.estadoId] = true
                })

                $timeout(function() {
                    $scope.$apply(() => {
                        $scope.dados.empresa_servicos = _servicos
                        $scope.dados.empresa_estados  = _estados
                    })
                }, 500)

            })
            .catch(err => {
                console.log(err)
            })
   }

    $scope.upsertEmpresa = (dados) => {

        configuracoesAPI.UpsertEmpresa(dados)
            .then(resp => {
                console.log(resp)
                if (resp.status === 200) {
                    swal("Sucesso!", `Suas configurações foram salvas com sucesso!`, "success")
                        .then(resp => {
                            if (resp) {
                                location.reload()
                            }
                        })
                        .catch(err => {
                            console.log(err)
                        })
                }
            })
            .catch(err => {
                console.log(err)
            })

    }

    $scope.uploadLogotipo = function (file) {
        Upload.upload({
            url: '/api/configuracoes/empresa/upload-logotipo',
            data: { file: file },
            headers: { 'x-access-token': $cookies.get('__grclean-tkn') }
        }).then(function (resp) {
            console.log('Success ' + resp.config.data.file.name + 'uploaded. Response: ' + resp.data);
        }, function (resp) {
            console.log('Error status: ' + resp.status);
        }, function (evt) {
            var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
            console.log('progress: ' + progressPercentage + '% ' + evt.config.data.file.name);
        });
    }

    $scope.uploadLogotipoMini = function (file) {
        Upload.upload({
            url: '/api/configuracoes/empresa/upload-logotipo-mini',
            data: { file: file },
            headers: { 'x-access-token': $cookies.get('__grclean-tkn') }
        }).then(function (resp) {
            console.log('Success ' + resp.config.data.file.name + 'uploaded. Response: ' + resp.data);
        }, function (resp) {
            console.log('Error status: ' + resp.status);
        }, function (evt) {
            var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
            console.log('progress: ' + progressPercentage + '% ' + evt.config.data.file.name);
        });
    }

    $scope.init()

}])
