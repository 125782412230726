import angular from 'angular'
import path from 'path'

angular.module('guaruclean.routes.chamados', [])

.config(function ($stateProvider) {

    $stateProvider

    .state({
        name: 'app.chamados',
        abstract: true,
        url: '/chamados',
        template: '<ui-view></ui-view>',
        data: {
            label: 'Chamados'
        }
    })

    .state({
        name: 'app.chamados.lista',
        controller: 'chamadosCtrl',
        url: '/lista',
        templateUrl: './../views/chamados/chamados.html',
        data: {
            label: 'Lista'
        }
    })

    .state({
        name: 'app.chamados.editar',
        controller: 'chamadosCtrl',
        url: '/editar/:chamadoId',
        templateUrl: './../views/chamados/chamados-form.html',
        data: {
            label: 'Editar'
        }
    })

    .state({
        name: 'app.chamados.novo',
        controller: 'chamadosCtrl',
        url: '/novo',
        templateUrl: './../views/chamados/chamados-form.html',
        data: {
            label: 'Novo'
        }
    })

})