import angular from 'angular'
import path from 'path'

angular.module('guaruclean.routes.recados', [])

.config(function ($stateProvider) {

    $stateProvider

    .state({
        name: 'app.recados',
        abstract: true,
        url: '/recados',
        template: '<ui-view></ui-view>',
        data: {
            label: 'Recados'
        }
    })

    .state({
        name: 'app.recados.lista',
        controller: 'recadosCtrl',
        url: '/lista',
        templateUrl: './../views/recados/recados.html',
        data: {
            label: 'Lista'
        }
    })

    .state({
        name: 'app.recados.novo',
        controller: 'recadosCtrl',
        url: '/novo',
        templateUrl: './../views/recados/recados-form.html',
        data: {
            label: 'Novo'
        }
    })

    .state({
        name: 'app.recados.form',
        controller: 'recadosCtrl',
        url: '/editar/:recadoId',
        templateUrl: './../views/recados/recados-form.html',
        data: {
            label: 'Editar'
        }
    })

})