import angular from 'angular'

angular.module('guaruclean.controllers.app', [])
.controller('appCtrl',['$scope', '$rootScope', '$state', function ($scope, $rootScope, $state) {

   $scope.init = () => {
       console.log("Iniciou controle app")

   }

   $scope.init()

}])
