import angular from 'angular'
import path from 'path'

angular.module('guaruclean.routes.clientes', [])

.config(function ($stateProvider) {

    $stateProvider

    .state({
        name: 'app.clientes',
        abstract: true,
        url: '/clientes',
        template: '<ui-view></ui-view>',
        data: {
            label: 'Clientes'
        }
    })

    .state({
        name: 'app.clientes.lista',
        controller: 'clientesCtrl',
        url: '/lista',
        templateUrl: './../views/clientes/clientes.html',
        data: {
            label: 'Lista'
        }
    })

    .state({
        name: 'app.clientes.editar',
        controller: 'clientesCtrl',
        url: '/editar/:clienteId',
        templateUrl: './../views/clientes/clientes-form.html',
        data: {
            label: 'Editar'
        }
    })

    .state({
        name: 'app.clientes.novo',
        controller: 'clientesCtrl',
        url: '/novo',
        templateUrl: './../views/clientes/clientes-form.html',
        data: {
            label: 'Novo'
        }
    })

})